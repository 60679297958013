<template>
  <div>
    <!-- <ExpoHeader></ExpoHeader> -->
    <div class="stiky-block" v-if="popup">
      <div  class="b-block-close"
        @click="closePopupBlock()">
        <img class="b-block-close--btn"
          src="/images/b-close.png" alt="">
      </div>
      <h1>{{$t('land.popupH')}}</h1>
      <p>{{$t('land.popupP')}}</p>
      <router-link to="/expo" class="section_1-content--regist inpopup">
        <span class="regist-text">{{$t('land.expbutton1')}}</span>
      </router-link>
    </div>
    <div class="container-fluid main-landing">
      <div class="main_1-background container-fluid">
        <div class="side-back back-1"></div>
        <div class="side-back back-2"></div>
        <div class="container main-section section_1">
          <div class="section_1-content skewElem">
            <h1 class="h2">
              {{$t('land.h1-sec1-1')}}
              <br class="land-br" >
              {{$t('land.h1-sec1-2')}}
              <br class="land-br" >
              {{$t('land.h1-sec1-3')}}
            </h1>
            <span class="section_1-content--more">
              {{$t('land.text1-1')}}
              <br>
              <br>
              <strong>{{$t('land.text1-2')}}</strong>
            </span>
            <router-link to="/expo" class="section_1-content--regist">
              <span class="regist-text">{{$t('land.expbutton1')}}</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="container main-section section_1 section_1-mobile">
        <div class="section_1-content skewElem">
          <h2 class="h2">
            {{$t('land.h1-sec1-1')}}
            <br class="land-br" >
            {{$t('land.h1-sec1-2')}}
            <br class="land-br" >
            {{$t('land.h1-sec1-3')}}
          </h2>
          <span class="section_1-content--more">
            {{$t('land.text1-1')}}
            <br>
            <br>
            <strong>{{$t('land.text1-2')}}</strong>
          </span>
        <router-link to="/expo" class="section_1-content--regist">
            <span class="regist-text">{{$t('land.expbutton1')}}</span>
          </router-link>
        </div>
      </div>
      <div class="container main-section section_2">
        <!-- section-2 -->
        <div class="section_2-content">
          <h2 class="h2 skewElem">{{$t('land.h1-sec2')}}</h2>
          <div class="row section_2-content--more">
            <router-link to="/catalog" class="col row more-plane skewElem">
              <div class="col col-sm-5 plane-img">
                <img src="images/icon/page/icon_1_page_2.png" alt="" class="img-art js-img-art">
              </div>
              <div class="col col-sm-10 col-xl-7 plane-info">
                <div class="info-head">{{$t('land.info-head1')}}</div>
                <div class="info-more">{{$t('land.info-text1')}}</div>
              </div>
            </router-link>
            <!-- /Заменить -->
            <!-- мастер-класс -->
            <router-link to="/expo" class="col row more-plane skewElem ">
              <div class="col col-sm-5 plane-img ">
                <img src="images/icon/page/icon_2_page_2.png" alt="" class="img-video js-img-video">
              </div>
              <div class="col col-sm-10 col-xl-7 plane-info">
                <span class="info-head">{{$t('land.info-head2')}}</span>
                <span class="info-more info-more--botton">{{$t('land.info-text2')}}</span>
              </div>
              </router-link>
          </div>
          <!-- голосование -->
          <div class="row section_2-content--more">
            <a :href="links[$i18n.locale].vote" class="col row more-plane skewElem">
              <div class="col col-sm-5 plane-img img-vote js-img-vote">
                <img src="images/icon/page/icon_3_page_2.png" alt="" class="img-vote js-img-vote">
              </div>
              <div class="col col-sm-10 col-xl-7 plane-info">
                <span class="info-head">{{$t('land.info-head3')}}</span>
                <span class="info-more">{{$t('land.info-text3')}}</span>
              </div>
            </a>
            <!-- призы -->
            <a :href="links[$i18n.locale].prize" class="col row more-plane skewElem">
              <div class="col col-sm-5 plane-img img-catelog js-img-catalog">
                <img src="images/icon/page/icon_4_page_2.png" alt="" class="img-catelog js-img-catalog">
              </div>
              <div class="col col-sm-10 col-xl-7 plane-info">
                <span class="info-head">{{$t('land.info-head4')}}</span>
                <span class="info-more info-more--botton">{{$t('land.info-text4')}}</span>
              </div>
            </a>
          </div>
          <div class="section_2-content--slider">
            <div class="swiper-button-prev--section-2 skewElem">
              <img src="images/interface/slider/pre_button.png" alt="" class="slider-button">
            </div>
            <div class="swiper-button-next--section-2 skewElem">
              <img src="images/interface/slider/next_button.png" alt="" class="slider-button">
            </div>
            <!-- Slider main container -->
            <div v-swiper:secondSwiper="swiperOption2" class="swiper-container swiper-container--section-2">
              <!-- Additional required wrapper -->
              <div class="swiper-wrapper swiper-wrapper--section-2">
                <!-- Slides -->
                <div class="swiper-slide swiper-slide-section--2 slide-art">
                  <!-- заменить -->
                  <router-link to="/catalog" class="col row more-plane">
                    <div class="col plane-img">
                      <img src="images/icon/page/icon_1_page_2.png" alt="" class="img-art js-img-art">
                    </div>
                    <div class="col plane-info">
                      <span class="info-head">{{$t('land.info-head1')}}</span>
                      <span class="info-more">{{$t('land.info-text1')}}</span>
                    </div>
                  </router-link>
                  <!-- заменить -->
                </div>
                <div class="swiper-slide swiper-slide-section--2 slide-video">
                  <a :href="links[$i18n.locale].mclasses" class="col row more-plane">
                    <div class="col col-sm-4 plane-img ">
                      <img src="images/icon/page/icon_2_page_2.png" alt="" class="img-video js-img-video">
                    </div>
                    <div class="col col-sm-8 plane-info">
                      <span class="info-head">{{$t('land.info-head2')}}</span>
                      <span class="info-more info-more--botton">{{$t('land.info-text2')}}</span>
                    </div>
                  </a>
                </div>
                <div class="swiper-slide swiper-slide-section--2 slide-vote">
                  <a :href="links[$i18n.locale].vote" class="col row more-plane">
                    <div class="col col-sm-4 plane-img img-vote js-img-vote">
                      <img src="images/icon/page/icon_3_page_2.png" alt="" class="img-vote js-img-vote">
                    </div>
                    <div class="col col-sm-8 plane-info">
                      <span class="info-head">{{$t('land.info-head3')}}</span>
                      <span class="info-more">{{$t('land.info-text3')}}</span>
                    </div>
                  </a>
                </div>
                <div class="swiper-slide swiper-slide-section--2 slide-catalog">
                  <a :href="links[$i18n.locale].prize"  class="col row more-plane">
                    <div class="col col-sm-4 plane-img img-catelog js-img-catalog">
                      <img src="images/icon/page/icon_4_page_2.png" alt="" class="img-catelog js-img-catalog">
                    </div>
                    <div class="col col-sm-8 plane-info">
                      <span class="info-head">{{$t('land.info-head4')}}</span>
                      <span class="info-more info-more--botton">{{$t('land.info-text4')}}</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- /section-2 -->
      </div>
      <ExpoSpikers :key="$i18n.locale"></ExpoSpikers>
      <div class="main-section_3--background container-fluid">
        <div class="container main-section section_3">
          <!-- section-3 -->
          <div class="section_3-content">
            <h2 class="h2 skewElem">{{$t('land.h1-sec3')}}</h2>
            <span class="section_3-content--more skewElem">{{$t('land.text2-1')}}
              <br>
              <br>
              <br>
              {{$t('land.text2-2')}}
            </span>
            <div class="section_3-content--list">
              <div class="list skewElem">
                <div class="list-numder">
                  <img src="images/icon/page/sphere_exp.png" alt="" class="list-img">
                </div>
                <div class="list-name">
                  <span>{{$t('land.list-name1')}}</span>
                </div>
              </div>
              <div class="list skewElem">
                <div class="list-numder">
                  <img src="images/icon/page/sphere_ins.png" alt="" class="list-img">
                </div>
                <div class="list-name">
                  <span>{{$t('land.list-name2')}}</span>
                </div>
              </div>
              <div class="list skewElem">
                <div class="list-numder">
                  <img src="images/icon/page/sphere_vote.png" alt="" class="list-im">
                </div>
                <div class="list-name">
                  <span>{{$t('land.list-name3')}}</span>
                </div>
              </div>
              <div class="list skewElem">
                <div class="list-numder">
                  <img src="images/icon/page/sphere_gift.png" alt="" class="list-im">
                </div>
                <div class="list-name">
                  <span>{{$t('land.list-name4')}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- /section-3 -->
        </div>
      </div>
      <!-- section-4 -->
      <div class="container main-section section_4">
        <div class="section_4-content">
          <div class="section_4-content--block">
            <h2 class="h2">{{$t('land.h1-sec4')}}</h2>
            <div class="section_4-sob">
              <img src="images/background/background_sob_page_4.png" alt="" class="sob">
            </div>
            <span class="section_4-content--more">
              {{$t('land.text3')}}
            </span>
            <span class="section_4-content--author">{{$t('land.text4')}}</span>
            <a href="https://www.sobyanin.ru/districts" target="_blank" class="section_4-content--button">
              <span>{{$t('land.expbutton2')}}</span>
            </a>
          </div>
        </div>
      </div>
      <!-- /section-4 -->

      <!-- section-5 -->
      <div class="container main-section section_5">
        <div class="section_5-content">
          <h2 class="h2">{{$t('land.h1-sec5')}}</h2>
          <!-- slider -->
          <div class="section_5-content--slider">
            <!-- Slider navigation -->
            <div class="swiper-button-prev swiper-button-prev-js"></div>
            <div class="swiper-button-next swiper-button-next-js"></div>
            <!-- Slider main container -->
            <div v-swiper:objImageSwiper="swiperOption" class="swiper-container swiper-container--section-5 ">
              <!-- Additional required wrapper -->
              <div class="swiper-wrapper swiper-wrapper--section-5">
                <!-- Slides -->
                <a v-for="s in rewards" :key="s.name" :href="s.link" target="_blank" class="swiper-slide swiper-slide--section-5">
                  <img :src="s.img" alt="" class="slide-img">
                </a>
              </div>
            </div>
          </div>
          <!-- slider -->
        </div>
      </div>
      <!-- /section-5 -->
    </div>
    <ExpoFooter></ExpoFooter>
  </div>
</template>
<script>

import firebase from "firebase/app";
import "firebase/auth";
import { directive } from 'vue-awesome-swiper'

import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
// import ExpoHeader from "@/components/ExpoHeader";
import ExpoFooter from "@/components/ExpoFooter";
import ExpoSpikers from "@/components/ExpoSpikers";

gsap.registerPlugin(ScrollTrigger);


export default {
  name: "ExpoLanding",
  directives: {
    swiper: directive
  },
  components: {
    // ExpoHeader,
    ExpoFooter,
    ExpoSpikers,
  },
  data() {
    return {
      popup: true,
      links:{
        ru:{
          prize: "https://mrexpo.ru/prize/",
          mclasses: "https://mrexpo.ru/master-classes/urij-postrigaj/",
          vote:"https://mrexpo.ru/vote/"
        },
        en:{
          prize: "https://mrexpo.ru/en/prize/",
          mclasses: "https://mrexpo.ru/en/master-classes/urij-postrigaj/",
          vote:"https://mrexpo.ru/en/vote/"
        }
      },
      swiperOption: {
        loop: true,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: '.swiper-button-next-js',
          prevEl: '.swiper-button-prev-js',
        },
        breakpoints: {
        // when window width is >= 300px
          300: {
            slidesPerView: 1,
            spaceBetween: 20,
            slidesPerGroup: 1,
          },
          // when window width is >= 576px
          576: {
            slidesPerView: 2,
            spaceBetween: 20,
            slidesPerGroup: 1,
          },
          // when window width is >= 766px
          766: {
            slidesPerView: 3,
            spaceBetween: 20,
            slidesPerGroup: 1,
          },
          // when window width is >= 991px
          991: {
            slidesPerView: 4,
            spaceBetween: 20,
            slidesPerGroup: 1,
          },
          // when window width is >= 1440px
          1440: {
            slidesPerView: 6,
            spaceBetween: 20,
            slidesPerGroup: 1,
          },
          // when window width is >= 1980px
          1980: {
            slidesPerView: 6,
            spaceBetween: 24,
            slidesPerGroup: 1,
          },
        }
      },
      swiperOption2:{
        loop: true,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: '.swiper-button-next--section-2',
          prevEl: '.swiper-button-prev--section-2',
        },
        breakpoints: {
          // when window width is >= 300px
          300: {
            slidesPerView: 1,
            spaceBetween: 20,
            slidesPerGroup: 1,
          },
        }
      }
    }
  },
  mounted() {
    let proxy = { skew: 0 },
    skewSetter = gsap.quickSetter(".skewElem", "translateY", "px"), // fast
    clamp = gsap.utils.clamp(-120, 120); // don't let the skew go beyond 20 degrees.
    ScrollTrigger.create({
      onUpdate: (self) => {
        let skew = clamp(self.getVelocity() / -100);
        // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
        if (Math.abs(skew) > Math.abs(proxy.skew)) {
          proxy.skew = skew;
          gsap.to(proxy, {skew: 0, duration: 0.7, ease: "power3", overwrite: true, onUpdate: () => skewSetter(proxy.skew)});
        }
      }
    });
    // make the right edge "stick" to the scroll bar. force3D: true improves performance
    gsap.set(".skewElem", {transformOrigin: "center", force3D: true});

    gsap.to(".stiky-block", {
      scrollTrigger: {
        trigger: ".stiky-block",
        start: "top 100%",
        end: "top -210%",
        scrub: true,
        id: "scrub",
      },
      x: "-4020px"
    });
  },methods:{
    closePopupBlock(){
      this.popup = false;
    }
  },
  computed: {
    rewards: () => [
      {
        name: 'AiF',
        img:'/images/icon/slider/aif.svg',
        link:'https://aif.ru',
      },
      {
        name: 'DR',
        img:'/images/icon/slider/DR.svg',
        link:'https://dorognoe.ru',
      },
      {
        name: 'Eva',
        img:'/images/icon/slider/eva.svg',
        link:'https://eva.ru',
      },
      {
        name: 'ivi',
        img:'/images/icon/slider/ivi.svg',
        link:'https://www.ivi.ru',
      },
      {
        name: 'Mir',
        img:'/images/icon/slider/Mir_tv.svg',
        link:'http://www.mirtv.ru',
      },
      {
        name: 'M24',
        img:'/images/icon/slider/M24.svg',
        link:'https://www.m24.ru',
      },
      {
        name: 'rambler',
        img:'/images/icon/slider/Rambler.svg',
        link:'https://www.rambler.ru',
      },
      {
        name: 'riamo',
        img:'/images/icon/slider/RIAMO.svg',
        link:'https://riamo.ru',
      },
      {
        name: 'kudago',
        img:'/images/icon/slider/KudaGo.svg',
        link:'https://kudago.com/msk/',
      },
      {
        name: 'ok',
        img:'/images/icon/slider/ok.svg',
        link:'https://ok.ru',
      },
      {
        name: 'vk',
        img:'/images/icon/slider/VK.svg',
        link:'https://vk.com/feed',
      },
      {
        name: 'bombora',
        img:'/images/icon/slider/bombora.svg',
        link:'https://bombora.ru',
      },
      {
        name: 'superjob',
        img:'/images/icon/slider/superjob.svg',
        link:'https://www.superjob.ru',
      },
      {
        name: 'litres',
        img:'/images/icon/slider/litres.svg',
        link:'https://www.litres.ru',

      },{
        name: 'osd',
        img:'/images/icon/slider/logo_osd.svg',
        link:' https://www.osd.ru/',
      },{
        name: 'stranaMam',
        img:'/images/icon/slider/StranaMam.svg',
        link:'https://www.stranamam.ru/',
      },{
        name: 'ria',
        img:'/images/icon/slider/ria.svg',
        link:' https://ria.ru/',
      },{
        name: 'VDNH',
        img:'/images/icon/slider/vdnkh.svg',
        link:'https://vdnh.ru',
      },
    ]
  }
}
</script>
<style>
.section_1-content--regist{
  animation-name: scalingBtn;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.section_1-content--regist:hover{
  animation-name: none;
}
@keyframes scalingBtn{
  50%{
    transform: scale(1);
  }
  75%{
    transform: scale(1.1);
  }
  100%{
    transform: scale(1);
  }
}
.stiky-block{
  position: fixed;
  width: min-content;
  right: -4000px;
  top: 80px;
  background: #fff;
  padding: 30px;
  font-weight: bold;
  border-radius: 30px;
  z-index: 100;
  color: #232176;
  box-sizing: content-box;
}
@media(max-width: 991px){
  .stiky-block{
    padding-top: 48px;
  }
}
.stiky-block p{
  font-size: 19px;
}
.stiky-block h1{
  white-space: nowrap;
  font-family: Gerbera-Bold;
}
.stiky-block .section_1-content--regist.inpopup span{
  line-height: 18px;
  white-space: nowrap;
  font-size: 18px;
}
.stiky-block .b-block-close{
  padding: 10px;
  width: 40px;
  height: 40px;
}
.stiky-block .section_1-content--regist.inpopup{
  width: min-content;
  padding: 14px;
  height: min-content;
  font-size: 14px;
}

.b-block-close--btn {
    width: 100%;
    height: 100%;
}
</style>
