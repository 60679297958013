<template>
  <div class="container main-section section-speakers">
    <div class="speakers-content">
      <h2 class="h2">{{$t('land.info-speakers')}}</h2>
      <!-- slider -->
      <div class="speakers-content--slider">
        <!-- Slider navigation -->
        <div class="swiper-button-prev swiper-button-prev-sp"></div>
        <div class="swiper-button-next swiper-button-next-sp"></div>
        <!-- Slider main container -->
        <div v-swiper:objImageSwiper="swiperOptionSP" class="swiper-container swiper-container--speakers">
          <div class="swiper-wrapper swiper-wrapper--speakers">
            <!-- Additional required wrapper -->
            <div v-for="key in speakers" :key="key.name" class="swiper-slide speakers-slide row">
              <div v-bind:style="{ backgroundImage: 'url(' + key.photo + ')' }" class="speakers-img"></div>
              <span class="speakers-name col">{{key.name}}</span>
              <span class="speakers-info col">{{key.short_desc}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- slider -->
  </div>
</template>
<script>
import { directive } from 'vue-awesome-swiper';

export default {
  name: "ExpoSpikers",
  directives: {
    swiper: directive
  },
  computed: {},
  data() {
    return {
      speakers: null,
      swiperOptionSP: {
        loop: true,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: '.swiper-button-next-sp',
          prevEl: '.swiper-button-prev-sp',
        },
        breakpoints: {
          200: {
            slidesPerView: 1,
            spaceBetween: 12
          },
          577: {
            slidesPerView: 2,
            spaceBetween: 12
          },
          800: {
            slidesPerView: 3,
            spaceBetween: 12
          },
          990: {
            slidesPerView: 4,
            spaceBetween: 12
          },
          1320: {
            slidesPerView: 4,
            spaceBetween: 12
          },
          1400: {
            slidesPerView: 5,
            spaceBetween: 24
          },
        }
      },
    }
  },
  mounted() {
    let adress = "https://mrexpo.ru/api/speakers/";
    console.log(localStorage.getItem("lang"))
    if(localStorage.getItem("lang")=="en"){
      adress = "https://mrexpo.ru/en/api/speakers/";
    }
    fetch(adress, {
      headers: {
        Authorization: 'Bearer 5mk0pONvoHjuG2nNmWs25zUe4pUgxepUgosmoiC5J20GWltkeyKoZKYJIRAhUmCo'
      }
    }).then(i => i.json()).then((i) => {
      this.speakers = i;
    });

  }
}
</script>
<style scoped>
  .speakers-img{
    padding: 0;
    border-radius: 20px;
    margin-bottom: 20px;
    height: 275px;
    -o-object-fit: cover;
    background-size: cover;
    background-position-x: center;
    background-repeat: no-repeat;
  }
  .speakers-name{
    font-weight: bold;
    font-size: 20px;
    line-height: 1.15;
    letter-spacing: -0.02em;
    color: #232176;
    margin-bottom: 10px;
  }
  .speakers-info{
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    color: #656565;
  }
  .speakers-content{
    padding-top: 8%;
  }
  .speakers-slide{
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .speakers-content--slider{
    position: relative;
    margin-top: 10%;
  }
  .swiper-container--speakers{
    width: 95%;
    margin: 0 auto;
  }
  @media (max-width: 1320px) {
    .swiper-container--speakers{
      width: 85%;
      margin: 0 auto;
    }
  }
  @media (max-width: 767px){
    .speakers-slide{
      align-items: center;
      justify-content: center;
    }
  }
  @media(max-width: 576px){
    .speakers-img{
      height: 350px;
    }
    .speakers-name {
      text-align: center;
    }
    .speakers-info{
      text-align: center;
    }
  }
  @media (max-width: 425px){
    .swiper-container--speakers{
      width: 80%;
      margin: 0 auto;
    }
  }

</style>
